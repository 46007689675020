<template>
  <b-row>
    <b-col cols="12">
      <b-card class="position-relative home-card">
        <h1 class="text-dark mb-1">Selamat <span v-text="greetTime" />, <span v-text="name" />!</h1>
        <h4 class="text-secondary" v-text="now"></h4>
        <img
          :src="cardBg"
          class="home-card-bg"
          alt="Good morning"
        >
      </b-card>
    </b-col>
    <b-col
      lg="6"
    >
      <b-card class="p-2 laporan">
        <h2>DK-LAMDIK</h2>
        <h4>(Data Kuantitatif-LAMDIK)</h4>
        <div class="text-right">
          <b-button
            class="button-pilih"
            variant="primary"
            size="lg"
            :to="{ name: 'op-lkps' }"
          >
            Pilih
          </b-button>
        </div>
      </b-card>
    </b-col>
    <b-col
      lg="6"
    >
      <b-card class="p-2 laporan">
        <h2>LED</h2>
        <h4 class="mb-2 mb-lg-0">(Laporan Evaluasi Diri)</h4>
        <div class="text-right">
          <b-button
            class="button-pilih"
            variant="primary"
            size="lg"
            :to="{ name: 'op-led' }"
          >
            Pilih
          </b-button>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton
  },
  setup() {
    const { full_name } = getUserData()
    const name = full_name.split(' ')[0]

    const time = moment().format('HH:mm:ss');
    let greetTime;
    
    if (time >= '05:00:00' && time < '11:00:00') {
      greetTime = 'pagi'
    } else if (time >= '11:00:00' && time < '16:00:00') {
      greetTime = 'siang'
    } else if (time >= '16:00:00' && time < '19:00:00') {
      greetTime = 'sore'
    } else {
      greetTime = 'malam'
    }

    const now = moment().format('dddd, D MMMM YYYY')
    return {
      name,
      now,
      cardBg: require('@/assets/images/pages/operator/home/card-bg.png'),
      greetTime
    }
  },
}
</script>

<style lang="scss" scoped>
  .home-card {
    height: 200px;

    h1 {
      font-size: 32px;
    }

    .home-card-bg {
      width: 300px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .laporan {
    h2 {
      font-size: 64px;
    }
  }

  @media (max-width: 576px) {
    .home-card {
      h1 {
        font-size: 18px;
      }
      .home-card-bg {
        display: none;
      }
    }

    .button-pilih {
      width: 100%;
    }
  }
</style>